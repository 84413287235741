<template>
  <v-card max-width="300px" class="mx-auto">
    <v-img height="100%" src="../assets/LA3.jpg"></v-img>
    <v-col>
      <v-avatar size="120" style="position:absolute; top: 80px;left:50%;transform: translate(-50%, 0);">
        <v-img src="../assets/louis.jpg"></v-img>
      </v-avatar>
    </v-col>
    <v-list-item color="rgba(0, 0, 0, .4)" style="padding-top:30px;text-align:center;">
      <v-list-item-content>
        <v-list-item-title class="title">Luis Hernandez</v-list-item-title>
        <v-list-item-subtitle>Software Engineer</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-text class="mt-5 ml-2">
      <div>
        <p><span class="mdi mdi-email mr-1"></span>manson.louis@gmail.com</p>
        <p><span class="mdi mdi-map-marker mr-1"></span>Tijuana, Mexico</p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'ProfileCrad'
  };
</script>

<style scoped>
  img{
    width: 150px;
    border-radius: 50%;
  }
  .profile {
    text-align: center;
    width: 100%;
    margin-bottom: 2rem;
  }
</style>
