<template >
  <v-parallax height="300" src="../assets/parallax.jpg"></v-parallax>
</template>

<script>
  export default {
    name: "TijuanaParallax",
  };
</script>

<style scoped>
</style>
