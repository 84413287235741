import { render, staticRenderFns } from "./FooterCodelouis.vue?vue&type=template&id=38d98b2c&scoped=true&"
import script from "./FooterCodelouis.vue?vue&type=script&lang=js&"
export * from "./FooterCodelouis.vue?vue&type=script&lang=js&"
import style0 from "./FooterCodelouis.vue?vue&type=style&index=0&id=38d98b2c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d98b2c",
  null
  
)

export default component.exports