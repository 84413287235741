<template>
  <span>
    <v-app-bar
      fixed
      class="primary"
      dark
      shrink-on-scroll
      fade-img-on-scroll
      height="600"
      src="../assets/cover.jpg">

      <!-- <v-app-bar-nav-icon @click="isActive = true"></v-app-bar-nav-icon> -->
      
      <v-toolbar-title>CodeLouis</v-toolbar-title>

      <v-spacer></v-spacer>

    </v-app-bar>
    <!-- <code-louis-drawer v-bind:status="isActive" v-on:drawerStatus="setDrawerOff" ></code-louis-drawer> -->
  </span>
</template>

<script>
export default {
  name: 'AppNav',
  data() {
    return {
      isActive: false
    };
  },
  methods: {
    setDrawerOff() {
      this.isActive = false
    }
  }
};
</script>

<style scoped>
  a {
    color: white !important;
    text-decoration: none;
  }
</style>
