<template>
  <v-card>
    <v-card-title>
      About me
    </v-card-title>
    <v-card-text>
       <div>
          <div>
            <p>
              My name is Luis and I am a software engineer with {{ experienceYears }} years of experience in which I have been able to work from governments to international companies working hard on projects related to education & EdTech in Mexico. I specialize in mobile development for Android and web applications mainly in Kotlin/Java, Ruby and Elixir.
            </p>
            <v-row>
              <v-col align="center" justify="center">
                <img src="../assets/android.png" alt="Android">
              </v-col>
              <v-col align="center" justify="center">
                <img src="../assets/ruby.png" alt="Ruby">
              </v-col>
              <v-col align="center" justify="center">
                <img src="../assets/elixir.png" alt="Elixir">
              </v-col>
            </v-row>
            <v-card-subtitle>
              <h3>Some Skills</h3>
            </v-card-subtitle>
            <v-row justify="center">
              <v-col outlined>
                <h3><span class="mdi mdi-xml mr-1"></span>Development</h3>
                <ul>
                  <li>TDD</li>
                  <li>Scrum</li>
                  <li>SOLID</li>
                  <li>Unit Testing</li>
                  <li>Continuous Integration</li>
                </ul>
              </v-col>
              <v-col outlined>
                <h3><span class="mdi mdi-cellphone mr-1"></span>Mobile</h3>
                <ul>
                  <li>Jetpack</li>
                  <li>MVP</li>
                  <li>MVVM</li>
                  <li>REST</li>
                </ul>
              </v-col>
              <v-col outlined>
                <h3><span class="mdi mdi-cloud-tags mr-1"></span>Backend</h3>
                <ul>
                  <li>MVC</li>
                  <li>API</li>
                  <li>CRUD</li>
                  <li>NoSql</li>
                </ul>
              </v-col>
              <v-col outlined>
                <h3><span class="mdi mdi-application-outline mr-1"></span>Frontend</h3>
                <ul>
                  <li>Material Design</li>
                  <li>Vue</li>
                  <li>Bootstrap</li>
                </ul>
              </v-col>
            </v-row>
          </div>
       </div>
    </v-card-text>
    <v-card-text>
      If you have any project you want to discuss, just contact me
    </v-card-text>
  </v-card>
</template>

<script>  
  export default {
    name: 'AboutMe',
    computed: {
      experienceYears: function () {
        return (new Date().getFullYear() - 2015)
      }
    }
  };
</script>

<style scoped>
  p {
    font-size: 16px;
  }
  img {
    filter: grayscale(100%);
    width: 50px;
    margin: 5px;
  }
</style>