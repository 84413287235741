<template>
  <v-footer dark padless>
    <v-card flat tile class="primary  white--text text-center" style="width: 100%;">
      <v-card-text>
        <v-btn
          v-for="(link, id) in links"
          :key="id"
          class="mx-4 white--text"
          icon
          :href="link.url"
          target="_blank">
          <v-icon size="24px">
            {{ link.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="white--text">
        Copyright © CodeLouis {{ new Date().getFullYear() }}
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "FooterCodelouis",
  data: () => ({
    links: [
      {
        icon: 'mdi-linkedin',
        url: "https://mx.linkedin.com/in/luis-hernandez-5b9518b4"
      },
      {
        icon: 'mdi-github',
        url: "https://github.com/louismanson"
      },
      {
        icon: 'mdi-instagram',
        url: "hhttps://www.instagram.com/manson.louis/"
      },
      {
        icon: 'mdi-twitter',
        url: "https://x.com/CodeLouisManson"
      }
    ],
  })
};
</script>

<style scoped>
  img {
    height: 30px;
    margin: 20px;
  }
</style>
