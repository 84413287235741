<template>
  <v-container mb-15 grid-list-lg>
    <v-layout row>
      <v-flex xs12 class="text-center display-1 font-weight-black my-5">
        Lenguages, Frameworks & Tech
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <div class="text-center">
        <img 
          v-for="(logo, id) in logos" 
          :key="id" 
          :src="require('@/assets/' + logo.name + '.png')"
          :alt="logo.alt">
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Experience",
  data: () => ({
    logos: [
      {
        name: 'android',
        alt: 'Android'
      },
      {
        name: 'kotlin',
        alt: 'Kotlin'
      },
      {
        name: 'java',
        alt: 'Java'
      },
      {
        name: 'ruby',
        alt: 'Ruby'
      },
      {
        name: 'elixir',
        alt: 'Elixir'
      },
      {
        name: 'javascript',
        alt: 'Javascript'
      },
      {
        name: 'rails',
        alt: 'Ruby on Rails'
      },
      {
        name: 'pheonix',
        alt: 'Pheonix'
      },
      {
        name: 'flutter',
        alt: 'Flutter'
      },
      {
        name: 'logo',
        alt: 'VueJs'
      },
      {
        name: 'wordpress',
        alt: 'Wordpress'
      },
      {
        name: 'polymer',
        alt: 'Polymer'
      },
      {
        name: 'node',
        alt: 'NodeJS'
      },
      {
        name: 'tensor',
        alt: 'TensorFlow'
      },
      {
        name: 'gs',
        alt: 'Google Script'
      },
      {
        name: 'docker',
        alt: 'Docker'
      },
      {
        name: 'travis',
        alt: 'Travis CI'
      },
      {
        name: 'firebase',
        alt: 'Firebase'
      },
      {
        name: 'cloud',
        alt: 'Google Cloud'
      },
      {
        name: 'gsuite-logo',
        alt: 'Google Workspace'
      },
      {
        name: 'mongodb',
        alt: 'MongoDB'
      },
      {
        name: 'couchdb',
        alt: 'CouchDB'
      },
      {
        name: 'bootstrap',
        alt: 'Bootstrap'
      },
      {
        name: 'vuetify',
        alt: 'Vuetify'
      },
      {
        name: 'bulma',
        alt: 'Bulma'
      }
    ],
  }),
};
</script>

<style scoped>
  img {
    width: 80px;
    margin: 5px;
  }
</style>
