<template>
  <v-container mb-15 mt-15 grid-list-lg>
    <v-layout row wrap>
      <div class="text-center">
        <img src="../assets/see.png" alt="Sistema Educativa Estatal">
        <img src="../assets/googlee.png" alt="Google for Education">
        <img src="../assets/samsung.png" alt="Samsung">
        <img src="../assets/amco.png" alt="AMCO">
        <img src="../assets/learninglocker.png" alt="Learning Locker">
        <img src="../assets/ardogorri.png" alt="Ardogorri">
        <img src="../assets/rescatemos.png" alt="Rescatemos Fundacion">
        <img src="../assets/holala.png" alt="holala.tv">
        <img src="../assets/yabuza.png" alt="Yabuza">
      </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Works",
};
</script>

<style scoped>
  img {
    width: 250px;
    margin: 20px;
  }
</style>
