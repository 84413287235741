<template>
  <div class="home" >
    <v-card style="background: #f2f2f2" flat height="650px"></v-card>
    <app-nav></app-nav>
    <about-me></about-me>
    <experience></experience>
    <tijuana-parallax></tijuana-parallax>
    <works></works>
  </div>
</template>

<script>
import AboutMe from "../components/AboutMe.vue";
import Experience from "../components/Experience.vue";
import Works from "../components/Works.vue";
import TijuanaParallax from '../components/TijuanaParallax.vue';
import AppNav from '../components/AppNav.vue';

export default {
  name: "Home",
  components: {
    AboutMe,
    Experience,
    Works,
    TijuanaParallax,
    AppNav
  }
};
</script>
