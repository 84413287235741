<template>
  <v-container mb-13 grid-list-lg>
    <v-layout row>
      <v-col cols="12" md="4">
        <profile-card></profile-card>
      </v-col>
      <v-col cols="12" md="8">
        <about-me-card></about-me-card>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import AboutMeCard from './AboutMeCard.vue';
import ProfileCard from './ProfileCard.vue';
  export default {
  components: { ProfileCard, AboutMeCard },
    name: 'AboutMe'
  };
</script>

<style scoped>
  img{
    width: 150px;
    border-radius: 50%;
  }
  .profile {
    text-align: center;
    width: 100%;
    margin-bottom: 2rem;
  }
</style>
