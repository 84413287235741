<template>
  <v-app style="background: #f2f2f2">
    <v-main transition="slide-x-transition">
        <router-view></router-view>
    </v-main>
    <footer-codelouis></footer-codelouis>
  </v-app>
</template>

<script>
  import FooterCodelouis from './components/FooterCodelouis.vue';
  export default {
    components: { FooterCodelouis },
    name: 'App'
  };
</script>

<style>
</style>
